<template>
  <section id="PageSingleMovie" class="col-12 wrapper-text">
    <div
        class="card mb-4"
        v-if="this.$store.state.getMovie.status.movieShowLoading"
    >
      <div class="card-header p-0 m-0">
        <img
            :src="imageSizeMovie('original') + movie.backdrop_path"
            class="img-fluid w-100"
            :alt="movie.original_title"
            :title="movie.original_title"
            v-if="movie.backdrop_path && !movie.adult"
        />
        <img
            src="../assets/no_cover.jpg"
            class="img-fluid w-100"
            :alt="movie.original_title"
            :title="movie.original_title"
            v-else
        />
      </div>
      <div class="card-body">
        <h1 class="text-center">{{ movie.title }}</h1>
        <p class="text-center" dir="ltr">{{ movie.overview }}</p>
        <div
            class="d-flex flex-wrap align-content-center justify-content-between"
        >
          <CardBoxData
              v-if="movie.genres.length > 0"
              title="ژانرها"
              color="bg-success"
          >
            <template v-slot:cardTitle>
              <span v-for="(genre, index) in movie.genres" :key="genre.id">
                {{ translatePersian(genre.name) }}
                <span v-if="index !== Object.keys(movie.genres).length - 1"
                >-
                </span>
              </span>
            </template>
          </CardBoxData>
          <CardBoxData title="رده سنی" color="bg-info">
            <template v-slot:cardTitle>
              {{ movie.adult ? "بزرگسالان" : "خانوادگی" }}
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.production_companies.length > 0"
              title="کمپانی سازنده"
              color="bg-color-primary-3"
          >
            <template v-slot:cardTitle>
              <span
                  v-for="(
                  production_companie, index
                ) in movie.production_companies"
                  :key="production_companie.id"
              >
                {{ production_companie.name }}
                <span
                    v-if="
                    index !== Object.keys(movie.production_companies).length - 1
                  "
                >-
                </span>
              </span>
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.release_date"
              title="تاریخ انتشار"
              color="bg-primary"
          >
            <template v-slot:cardTitle>
              {{ movie.release_date }}
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.production_countries.length > 0"
              title="کشور سازنده"
              color="bg-color-primary-4"
          >
            <template v-slot:cardTitle>
              {{ movie.production_countries[0].name }}
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.vote_count"
              title="تعداد رای"
              color="bg-secondary"
          >
            <template v-slot:cardTitle> {{ movie.vote_count }} رای </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.vote_average"
              title="میانگین امتیاز"
              color="bg-danger"
          >
            <template v-slot:cardTitle>
              {{ movie.vote_average }} از 10
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.release_date"
              title="تاریخ اکران"
              color="bg-dark"
          >
            <template v-slot:cardTitle>
              {{ movie.release_date }}
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.imdb_id"
              title="شناسه IMBD"
              color="bg-color-primary-2"
          >
            <template v-slot:cardTitle>
              <a
                  :href="movie.homepage"
                  target="_blank"
                  class="text-white"
                  v-if="movie.homepage"
                  v-text="movie.imdb_id"
              ></a>
              <p class="text-white m-0" v-else>{{ movie.imdb_id }}</p>
            </template>
          </CardBoxData>
          <CardBoxData
              v-if="movie.budget"
              title="بودجه تولید"
              color="bg-warning"
          >
            <template v-slot:cardTitle> $ {{ movie.budget }} </template>
          </CardBoxData>
        </div>
      </div>
    </div>
    <div class="card mb-4" v-else>
      <Spinner />
    </div>
    <div
        class="card mb-4"
        v-if="
        !movie.adult &&
        this.$store.state.getMovie.status.imageMovieShowLoading &&
        imageMovieBackdrops.length
      "
    >
      <div class="card-header bg-success">
        <h4 class="text-center text-white my-2">تصاویر {{ movie.title }}</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div
              class="col-md-6 my-2"
              v-for="photo in imageMovieBackdrops"
              :key="photo.file_path"
          >
            <img
                :src="imageSizeMovie('w780') + photo.file_path"
                class="img-fluid w-100 rounded-1"
                :alt="movie.original_title"
                :title="movie.original_title"
                v-if="photo.file_path"
            />
            <img
                src="../assets/no_cover.jpg"
                class="img-fluid w-100 rounded-1"
                :alt="movie.original_title"
                :title="movie.original_title"
                v-else
            />
          </div>
        </div>
      </div>
    </div>
    <div
        class="card mb-4"
        v-if="
        !movie.adult &&
        this.$store.state.getMovie.status.imageMovieShowLoading &&
        imageMoviePosters.length
      "
    >
      <div class="card-header bg-success">
        <h4 class="text-center text-white my-2">پوستر {{ movie.title }}</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div
              class="col-md-3 col-lg-2 my-2"
              v-for="photo in imageMoviePosters"
              :key="photo.file_path"
          >
            <img
                :src="imageSizeMovie('w342') + photo.file_path"
                class="img-fluid w-100 rounded-1"
                :alt="movie.original_title"
                :title="movie.original_title"
                v-if="photo.file_path"
            />
            <img
                src="../assets/no_cover.jpg"
                class="img-fluid w-100 rounded-1"
                :alt="movie.original_title"
                :title="movie.original_title"
                v-else
            />
          </div>
        </div>
      </div>
    </div>
    <TrailersMovie
        :movie="movie"
        :trailersMovie="trailersMovie"
    ></TrailersMovie>
    <SimilarMovie :movie="movie" :similarMovie="similarMovie"></SimilarMovie>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageSingleMovie",
  components: {
    'SimilarMovie': defineAsyncComponent(() =>
        import('@/components/section/SimilarMovie')
    ),
    'TrailersMovie': defineAsyncComponent(() =>
        import('@/components/section/TrailersMovie')
    ),
    'CardBoxData': defineAsyncComponent(() =>
        import('@/components/template/cardBoxData')
    ),
    'Spinner': defineAsyncComponent(() =>
        import('@/components/template/spinner')
    )
  },
  watch: {
    "$route.params.id": function (id) {
      if (this.$route.params.id) {
        this.getMovie(id);
        this.getImageMovie(id);
        this.getTrailersMovie(id);
        this.getSimilarMovie(id);
      }
    },
  },
  computed: {
    movie() {
      return this.$store.state.getMovie.movie;
    },
    imageMovieBackdrops() {
      return this.$store.getters["getMovie/imageMovieBackdropsFilterd"];
    },
    imageMoviePosters() {
      return this.$store.getters["getMovie/imageMoviePostersFilterd"];
    },
    trailersMovie() {
      return this.$store.getters["getMovie/trailersMovieFilterd"];
    },
    similarMovie() {
      return this.$store.getters["getMovie/similarMovieFilterd"];
    },
  },
  methods: {
    getMovie(id) {
      let query = `/movie/${id}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setMovie",
      });
    },
    getImageMovie(id) {
      let query = `/movie/${id}/images?api_key=${this.$store.state.getMovie.api_key}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setImageMovie",
      });
    },
    getTrailersMovie(id) {
      // let query = `/movie/${id}/similar?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=1`
      let query = `/movie/${id}/videos?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTrailersMovie",
      });
    },
    getSimilarMovie(id) {
      let query = `/movie/${id}/similar?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=1`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setSimilarMovie",
      });
    },
  },
  mounted() {
    this.getMovie(this.$route.params.id);
    this.getImageMovie(this.$route.params.id);
    this.getTrailersMovie(this.$route.params.id);
    this.getSimilarMovie(this.$route.params.id);
  },
  unmounted() {
    this.$store.commit("getMovie/setResetMovie");
    this.$store.commit("getMovie/setResetImageMovie");
    this.$store.commit("getMovie/setResetTrailersMovie");
    this.$store.commit("getMovie/setResetSimilarMovie");
  },
};
</script>

<style scoped></style>
